import React from "react";
import { connect } from 'react-redux'
import { fetchAllQuestions } from '../../api/exams/actions';
import QuestionWizard from '../../components/question-wizard'

class ExamPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }
    componentDidMount(){
        this.props.store.dispatch(fetchAllQuestions(this.props.exam));
    }

    render() {
        let exams = this.props.exams;
        if (!exams.isFetching && exams.results && exams.extraInformation) {
            return (<QuestionWizard exams={exams} answers={this.props.answers} examNo={this.props.exam} store={this.props.store} title={this.props.title} lang={this.props.lang} />)
        } else {
            return(<div>Spinner</div>)
        }

    }
}

function mapStateToProps(state) {

    var f = {
        exams: {},
        answers: {},
    }
    f.exams = (state.exams === undefined) ? {} : state.exams
    f.answers = (state.answers === undefined) ? {} : state.answers
    return f;
}

export default connect(mapStateToProps)(ExamPage);