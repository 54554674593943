import React from 'react';
import { all } from 'q';

import { addAnswer } from '../../api/answers/actions';
import { submitAnswers } from '../../api/exams/actions';

import ResultOverview from '../result-overview';
import AllResults from '../all-results';
const translations = [
    gettext('Spurning')
]

function loadReadSpeaker(questionId, l) {
    let lang = 'is_is';
    let voice = 'is_karl';

    if(l == 'en'){
        lang = 'en_uk';
        voice = 'Alice';
    }else if(l == 'pl'){
        lang = 'pl_pl';
        voice = 'pl_jacek';
    }
    var readSpeak = ""
    readSpeak += '<div id="readspeaker_button1" class="rs_skip rsbtn rs_preserve">';
    readSpeak += '<a rel="nofollow" class="rsbtn_play" accesskey="L" title="' + gettext('Hlustaðu á þessa síðu lesna af ReadSpeaker webReader') + '"';
    readSpeak += 'href="//app-eu.readspeaker.com/cgi-bin/rsent?customerid=11175&amp;lang='+lang+'&amp;voice='+voice+'&amp;readid='+ questionId +'">';                      
    readSpeak += '<span class="rsbtn_left rsimg rspart"><span class="rsbtn_text"><span>' + gettext('Hlusta') + '</span></span></span>';
    readSpeak += '<span class="rsbtn_right rsimg rsplay rspart"></span>';
    readSpeak += '</a>';
    readSpeak += '</div>';
    $('.readSpeaker').show().html(readSpeak)
    ReadSpeaker.init();
    setTimeout(() => {
        if (typeof rspkr != "undefined" && typeof rspkr.ui != "undefined"){
            rspkr.ui.addClickEvents();
        }
    }, 250);
}

class QuestionWizard extends React.Component {

    constructor(props) {
        super(props)
        let questionIds = []
        this.props.exams.results.map(q => questionIds.push(q.id))
        this.state = {
            currentQuestion: 1,
            NoOfQuestions: this.props.exams.results.length,
            answeredQuestions: [],
            isAllResultsActive: false,
            showIntro: true,
            questionIds : questionIds,
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.currentQuestion !== prevState.currentQuestion) {
            loadReadSpeaker(this.state.questionIds[this.state.currentQuestion-1], this.props.lang);
        }
    }

    lastQuestion() {
        this.setState({
            currentQuestion: this.state.currentQuestion - 1,
        })
    }

    nextQuestion(questionId) {
        this.setState({
            currentQuestion: this.state.currentQuestion + 1,
        })
        
    }

    selectAnswer(question, e) {
        if (question.question_type == 'multi_choice_block') {
            var checkboxes = document.getElementsByName(question.id);
            var answerArray = [];
            for (var i = 0; i < checkboxes.length; i++) {
                if (checkboxes[i].checked) {
                    answerArray = answerArray.concat(true)
                } else {
                    answerArray = answerArray.concat(false)
                }
            }
            this.props.store.dispatch(addAnswer({ key: question.id, value: answerArray }))
        } else if (question.question_type == 'true_false_block') {
            this.props.store.dispatch(addAnswer({ key: question.id, value: e.currentTarget.value == 'true' ? true : false }))
        }
    }

    finishExam() {
        this.props.store.dispatch(submitAnswers(this.props.examNo, this.props.answers.answerList))
    }

    checkIcon() {
        return (
            <svg width="25px" height="24px" viewBox="0 0 25 24">
                <g id="Mobile" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="Verkefni-yfirferð" transform="translate(-339.000000, -931.000000)" fill="#34B843" fill-rule="nonzero">
                        <polygon id="Path-Copy-2" points="347.57711 955 339 946.419531 342.206898 943.346994 347.132286 948.275173 360.415659 931 364 933.641877"></polygon>
                    </g>
                </g>
            </svg>
        )
    }

    renderAnswerOptions(question) {
        if (question.question_type == 'multi_choice_block') {
            let allOptions =
                question.options.map((option, i = i + 1) => {
                    return <>
                        <div className="answer-checkbox">
                            <input type="checkbox" value={question.id + "_" + i} name={question.id} key={question.id + "_" + i} onChange={this.selectAnswer.bind(this, question)}></input>
                            <label>
                                {this.checkIcon()}
                                {option}
                            </label>
                        </div>
                    </>
                })
            return allOptions;
        } else if (question.question_type == 'true_false_block') {
            return (
                <>
                    <div className="answer-radio">
                        <span className="answer-container">
                            <span className="radio-container">
                                <input type="radio" key={question.id + "_true"} id="true" name={question.id} value={true} onChange={this.selectAnswer.bind(this, question)}></input>
                                <label htmlFor="true">{gettext('Rétt')}</label>
                            </span>
                        </span>
                        <span className="answer-container">
                            <span className="radio-container">
                                <input type="radio" key={question.id + "_false"} id="false" name={question.id} value={false} onChange={this.selectAnswer.bind(this, question)}></input>
                                <label htmlFor="false">{gettext('Rangt')}</label>
                            </span>
                        </span>
                    </div>
                </>
            )
        }

    }
    renderAllQuestions(questions) {
        let allQuestions = []
        if (questions) {
            allQuestions =
                questions.map((question, i = i + 1) => {
                    i = i + 1;
                    return <div className={this.state.currentQuestion == i ? "question " + i : "question hidden " + i} i={i} id={question.id}>
                            <div id={"question-" + i} className={question.question_type == 'true_false_block' ? "question-wrapper radio" : "question-wrapper checkbox"}>
                                <div className="title">{interpolate(gettext('Spurning %s'), [i])}</div>
                                {question.image !== "" ? <div className="img-container"><img src={question.image}></img></div> : null}
                                <div className="text">{question.question}</div>
                                {this.renderAnswerOptions(question)}
                            </div>
                            <div className="percentage-wrapper">
                                {this.renderPrevButton()}
                                <div className="percentage-completed rs_skip">
                                    <div className="percentage" style={{ width: (i - 1) / this.state.NoOfQuestions * 100 + '%' }}>
                                        <span>{i / this.state.NoOfQuestions * 100}%</span>
                                    </div>
                                </div>
                                {this.renderNextButton(questions, question)}
                                {this.renderFinishButton()}
                            </div>
                        </div>
                })
        }
        return allQuestions;
    }

    renderPrevButton() {
        if (1 !== this.state.currentQuestion) {
            return <div className="prev" onClick={this.lastQuestion.bind(this)}>
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-left" className="svg-inline--fa fa-chevron-left fa-w-10" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                    <path fill="currentColor" d="M34.52 239.03L228.87 44.69c9.37-9.37 24.57-9.37 33.94 0l22.67 22.67c9.36 9.36 9.37 24.52.04 33.9L131.49 256l154.02 154.75c9.34 9.38 9.32 24.54-.04 33.9l-22.67 22.67c-9.37 9.37-24.57 9.37-33.94 0L34.52 272.97c-9.37-9.37-9.37-24.57 0-33.94z"></path>
                </svg>
            </div>
        }
    }

    renderNextButton(questions, question) {
        let questionId = question.id;

        questions.map((question) => {
            this.props.answers.answerList.map((answer) => {
                if (answer.id == question.id) {
                    let state = this.state;
                    if (!state.answeredQuestions.includes(answer.id)) {
                        let newAnsweredQuestions = this.state.answeredQuestions.concat(answer.id)
                        state.answeredQuestions = newAnsweredQuestions
                    }
                }
            })
        })

        if (this.state.NoOfQuestions !== this.state.currentQuestion && this.state.answeredQuestions.includes(questionId)) {
            return <div className="next" onClick={this.nextQuestion.bind(this, questionId)}>
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-right" className="svg-inline--fa fa-chevron-right fa-w-10" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                    <path fill="currentColor" d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"></path>
                </svg>
            </div>
        }
    }

    renderFinishButton() {
        if (this.state.NoOfQuestions == this.state.answeredQuestions.length) {
            return <div className="finish" onClick={this.finishExam.bind(this)}>
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-right" className="svg-inline--fa fa-chevron-right fa-w-10" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                    <path fill="currentColor" d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"></path>
                </svg>
            </div>
        }
    }

    toggleAllResultsActive() {
        this.setState({
            isAllResultsActive: !this.state.isAllResultsActive,
        })
    }
    proceedToExam() {
        this.setState({
            showIntro: false
        })
        loadReadSpeaker(this.state.questionIds[0], this.props.lang);
    }

    render() {
        let questions = this.props.exams.results;
        let extraInfo = this.props.exams.extraInformation;

        if (this.props.exams.submitResponse.answers && this.props.exams.submitResponse.status == 200 && this.state.isAllResultsActive) {
            $('.readSpeaker').hide();
            return (
                <div className="exam">
                    <div className="exam-title">{this.props.title}</div>
                    <AllResults toggleAllResultsActive={this.toggleAllResultsActive.bind(this)} results={this.props.exams.submitResponse.answers} />
                </div>
            )
        } else if (this.props.exams.submitResponse.answers && this.props.exams.submitResponse.status == 200) {
            $('.readSpeaker').hide();
            return (
                <div className="exam">
                    <div className="exam-title">{this.props.title}</div>
                    <ResultOverview toggleAllResultsActive={this.toggleAllResultsActive.bind(this)} responses={this.props.exams.submitResponse} readAgainLink={extraInfo.read_again_link} nextPageLink={extraInfo.next_page_link} />
                </div>)
        } else if (this.state.showIntro === true) {
            $('.readSpeaker').hide();
            return (
                <div className="exam start-exam">
                    <div className="intro">
                        <div className="exam-title">{this.props.title}</div>
                        <div className="intro-icon">
                            <svg width="25px" height="24px" viewBox="0 0 25 24">
                                <g id="Mobile" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <g id="Verkefni-yfirferð" transform="translate(-339.000000, -931.000000)" fill="#34B843" fill-rule="nonzero">
                                        <polygon id="Path-Copy-2" points="347.57711 955 339 946.419531 342.206898 943.346994 347.132286 948.275173 360.415659 931 364 933.641877"></polygon>
                                    </g>
                                </g>
                            </svg>
                        </div>
                        <p className="intro-description">{gettext('Verkefni')}</p>
                    </div>
                    <div className="start-exam-container">
                        {/* <div>{extraInfo.title}</div> */}
                        <div className="text" dangerouslySetInnerHTML={{ __html: extraInfo.description }}></div>
                        {/* <button className="button re-read">Lesa kaflann aftur</button> */}
                        <a className="button re-read" href={this.props.exams.extraInformation.read_again_link}>{gettext('Lesa kaflann aftur')}</a>
                        <button className="button start" onClick={this.proceedToExam.bind(this)}>{gettext('Hefja verkefni')}</button>
                    </div>
                </div>
            )
        } else {
            $('.readSpeaker').show();
            return (
                <div className="exam">
                    <div className="exam-title">{this.props.title}</div>
                    <div className="question-container">
                        {this.renderAllQuestions(questions)}
                    </div>
                </div>
            )
        }
    }
}

QuestionWizard.defaultProps = {
}


export default QuestionWizard;